<template>
  <section class="dtc-main-section" id="">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件2
    </header>
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      {{ title }}
      <b-button
        hidden
        size="sm"
        variant="success"
        @click="save"
        class="mr-2 d-print-none dtc-print-btn2"
        style="display:inline-block;width:100px;height:40px;margin-top:-20px"
      >
        <i class="fas fa-save mr-1"></i>儲存變更
      </b-button>
    </header>
    <header class="dtc-grid-header8 mt-2">
      <div class="dtc-grid-height">序號</div>
      <div class="dtc-grid-height">培育學校</div>
      <div class="dtc-grid-height dtc-fill-requiredxxx">學生人數</div>
      <div class="dtc-grid-height">年級</div>
      <section class="dtc-heaer-s1" style="border:none;">
        <div class="dtc-col2">
          <div>經常門</div>
          <div>業務費(10,000元/每人每學期)</div>
        </div>
        <div class="dtc-col2">
          <div>資本門</div>
          <div>設備費(15,000 / 每人每學年)</div>
        </div>
        <div class="dtc-l-100">合計</div>
      </section>
    </header>
    <main class="dtc-grid-main">
      <div style="padding-top:5px">{{ serialNo }}</div>
      <div style="padding-top:5px">{{ schoolName }}</div>
      <div class="row-4-dtc">
        <div v-for="(item, idx) in map" :key="idx">
          {{ item.NumberOfPeople }}
        </div>
      </div>
      <div class="row-4-dtc">
        <div v-for="(item, idx) in map" :key="idx">{{ item.GradeName }}</div>
      </div>
      <div class="row-4-dtc">
        <div v-for="(item, idx) in map" :key="idx" class="text-right-mode">
          {{ $formatPrice(item.Service_fee) }}
        </div>
      </div>
      <div class="row-4-dtc">
        <div v-for="(item, idx) in map" :key="idx" class="text-right-mode">
          {{ $formatPrice(item.Equipment_costs) }}
        </div>
      </div>
      <div class="row-4-dtc">
        <div
          v-for="(item, idx) in map"
          :key="idx"
          class="text-right-mode"
          style="padding-right:8px"
        >
          {{ $formatPrice(item.SubTotal) }}
        </div>
      </div>
    </main>
    <footer class="dtc-note dtc-note2">
      <div style="text-align:center;font-size:16px;">總計</div>
      <div style="font-size:16px;padding-right:0px;" class="text-right-mode">
        {{ $formatPrice(total) }}
      </div>
    </footer>
    <!-- <footer class="dtc-note">
      <div>備註</div>
      <div>1.一年級係指107學年入學公費生。</div>
      <div>2.二年級係指106學年度入學公費生。</div>
      <div>3.三年級係指105學年度入學公費生。</div>
      <div>4.四年級係指104學年度入學公費生</div>
      <div>
        5.國立成功大學、國立陽明大學、中國醫藥大學、義守大學、國立台中科技大學及慈濟科技大學104學年無招生學生。
      </div>
      <div>6.國立陽明大學、中國醫藥大學及義守大學105學年度無招生學生。</div>
      <div>
        7.國立成功大學、義守大學、國立台北護理健康大學、長庚科技大學及弘光科技大學106學年度無招生學生。
      </div>
    </footer> -->
  </section>
</template>

<script>
import queryString from "query-string";
const schools = [];
const levels = ["一", "二", "三", "四"];
const rowObj = {};

export default {
  name: "doc2",
  data() {
    return {
      twYears,
      term: 1,
      schools,
      levels,
      pageRows: [],
      pageSize: 22,
      isHeaderVisible: true,
      bYear: "110",
      map: "",
      schoolArr: [],
      schoolName: "",
      total: 0,
      title: "",
      allData: {},
      serialNo: "",
    };
  },

  methods: {
    async save() {
      if (!window.location.href.includes("doc8-4")) return;
      try {
        await window.axios.put("Often/Put?id=" + this.map.SeqNo, this.map);
        this.$root.$emit("dtc-save-form-ok");
      } catch (e) {}
    },
    async updateRow(item) {
      item.NumberOfPeople = Number(item.NumberOfPeople);
      if (isNaN(item.NumberOfPeople)) item.NumberOfPeople = 0;
      item.Subtotal = item.NumberOfPeople * 10000;
      this.map.often_detail = [...this.map.often_detail];
    },
    async getData(reset) {
      reset ? (this.map.often_detail = []) : "";
      const {
        schoolcode: SchoolCode = "0003",
        byear: Byear = "110",
        term = "1",
        category: Category = "N",
      } = queryString.parse(location.href.split("?")[1]);
      let code = SchoolCode ? SchoolCode : "0003";
      const url = `Budgeting/GetOftenDetailPri?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`;
      try {
        const map = await window.axios.get(url);
        if (map) {
          this.map = map[0].budgeting_head[0].often_detail;
          this.map.forEach((s) => (this.total += s.SubTotal));

          this.map.sort(function(a, b) {
            return a.Grade - b.Grade;
          });

          this.title = map[0].Title;
          this.allData = map[0];
          this.serialNo = this.allData.budgeting_head[0].SerialNo;
        }

        this.map.forEach((k, i) => {
          if (k.Grade == 2) {
            k.GradeName = "二";
          } else if (k.Grade == 3) {
            k.GradeName = "三";
          } else if (k.Grade == 4) {
            k.GradeName = "四";
          }
        });
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
      if (!this.schoolArr.length)
        this.schoolArr = await window.axios.get("School/GetAll");
      this.schoolName = this.schoolArr.find(
        (s) => s.SchoolCode == SchoolCode
      ).SchoolName;
    },
  },
  async mounted() {
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "110",
      term = "1",
      category: Category = "N",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    Byear ? (this.bYear = Byear) : (this.bYear = "110");

    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }
    //check Can see and then get data
    this.getData();
  },
  beforeMount() {
    this.$root.$on("dtc-save-form", () => this.save());
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-fill-required,
.dtc-fill-required2 {
  position: relative;
  &::after {
    position: absolute;
    font-size: 16px;
    color: red;
    content: "*";
    top: -10px;
    left: 25px;
  }
}
.dtc-l-100 {
  line-height: 100px !important;
}
.dtc-col2 {
  display: grid;
  grid-template-rows: 40px 1fr;
  > div:last-child {
    line-height: 60px !important;
  }
  > div:first-child {
    border-bottom: 1px solid var(--dtc-border-color);
    line-height: 40px;
  }
}
.dtc-main-section {
  position: relative;
  --dtc-grid-header-height: 150px;
  --dtc-doc8-width: 858px;
  --dtc-border-color: var(--dark);
  margin: 30px auto;
  min-height: 120px;
  max-width: var(--dtc-doc8-width);
  font-size: 14px;
  text-align: center;
}
.dtc-grid-height {
  line-height: var(--dtc-grid-header-height);
}

.dtc-grid-header8 {
  position: sticky;
  //background: var(--dark);
  top: 50px;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  grid-template-columns: 36px 120px 61px 40px 600px;
  > div {
    height: var(--dtc-grid-header-height);
    border-right: 1px solid var(--dtc-border-color);
    text-align: center;
  }
  section {
    display: grid;
    position: relative;
    align-items: end;
    border-right: 1px solid var(--dtc-border-color);
    &::before,
    &::after {
      position: absolute;
      content: "實報實銷類";
      display: block;
      transform: translateX(-50%);
      width: max-content;
      height: 30px;
      font-size: 16px;
      top: 10%;
      left: 50%;
      right: 0;
      bottom: 0;
    }
    > div {
      max-height: 100px;
      padding-top: 2px;
      height: var(--dtc-grid-header-height);
      border-right: 1px solid var(--dtc-border-color);
      border-top: 1px solid var(--dtc-border-color);
    }
    > div:last-child {
      border-right: none;
    }
  }
  .dtc-heaer-s1 {
    grid-template-columns: repeat(3, 1fr);
    &::before {
      content: "各校培育偏鄉護理菁英計畫公費生經費";
    }
    &::after {
      content: "";
    }
  }
  .dtc-heaer-s2 {
    &::before {
      content: "定額核銷類";
    }
    &::after {
      content: "";
    }
    grid-template-columns: repeat(6, 1fr);
  }
  .dtc-heaer-s3 {
    &::before {
      content: "在補助額度內檢";
      top: 2%;
    }
    &::after {
      content: "附單據或證明類";
      top: 15%;
    }
    grid-template-columns: repeat(2, 1fr);
  }
}

.dtc-note,
.dtc-note2 {
  grid-column: 1/-1;
  text-align: left;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  height: 228px;
  grid-template-columns: 1fr;
  padding: 7px;
  > div:not(:first-child) {
    padding-left: 9px;
  }
}
.dtc-note2 {
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 185px;
}

.dtc-grid-main {
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  min-height: 44px;
  height: var(--height);
  // grid-template-columns: 36px 132px 51px 40px 360px 450px 180px 90px;
  grid-template-columns: 36px 120px 61px 40px repeat(3, 200px);
  font-size: 16px;

  > div {
    border-right: 1px solid var(--dtc-border-color);
    //line-height: var(--height);
  }
  > div:last-child {
    border-right: none;
  }
  .dtc-main-sec {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    border-right: 1px solid var(--dtc-border-color);
    > div {
      height: 20px;
      line-height: 21px;
      border-bottom: 1px solid var(--dtc-border-color);
    }
    > div:last-child {
      border-bottom: 0;
    }
  }
}
.dtc-title {
  display: inline-block;
  margin: auto;
  width: 858px;
  text-align: left;
  font-size: 16px;
}
.row-4-dtc {
  display: flex;
  flex-direction: column;

  > div {
    height: 28px;
    border-bottom: 1px solid black;
    line-height: 28px;
  }
  > div:last-child {
    border-bottom: none;
  }
}

.input-group-prepend .input-group-text {
  min-width: 150px;
}

@media print {
  #app .input-group-prepend .input-group-text {
    background: white;
    color: black;
  }
  #app .input-group-append .input-group-text {
    background: white;
    color: black;
  }
  #app {
    .input-group input,
    .input-group-text input,
    input,
    textarea,
    select,
    textarea:focus .input-group select {
      background: white;
      color: black;
    }
  }
}
.text-right-mode {
  text-align: right;
  padding-right: 5px;
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-main-section {
    --dtc-border-color: var(--dark) !important;
    width: var(--dtc-doc8-width);
    position: relative;
    //margin: 3px auto 60px auto;
    width: var(--dtc-doc8-width);
    color: black !important;
  }
}
</style>
